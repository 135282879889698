<template>
    <div class="card shadow h-100">
        <div class="card-body">
            <h5 class="card-title">Live comments</h5>
            <div class="d-flex flex-column justify-content-between h-100">
                <div class="comments my-4">
                    <div v-for="comment in reversedComments" :key="comment.id" class="comment"
                         :class="comment.user.username === user.username ? 'reverse' : null">
                        <img :src="`${resourcesUrl}/${comment.user.picture}`" class="comment-user-pic">
                        <div class="comment-content">
                            <div class="comment-user" v-if="comment.user.username">{{ comment.user.username }}</div>
                            <div class="comment-text" v-if="comment.text">{{ comment.text }}</div>
                        </div>
                    </div>
                </div>

                <emoji-text-input v-model="form.comment"
                                  @submit="postComment"
                                  :loading="loading"
                                  placeholder="Send a message..."
                                  :handle-submit="true"
                                  submit-text="SEND"
                                  class="mb-4"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import EmojiTextInput from "./EmojiTextInput"

    export default {
        props: ['socket', 'user', 'live', 'comments'],
        components: {
            EmojiTextInput,
        },
        data: function () {
            return {
                form: {
                    comment: null
                },
                loading: false,
                showEmojiPicker: false
            }
        },
        computed: {
            reversedComments() {
                let comments = this.comments.slice()
                comments.sort((a, b) => a.created_at - b.created_at)
                return comments.slice().reverse()
            },
        },
        methods: {
            postComment() {
                this.loading = true
                this.socket.emit('postComment', {broadcastId: this.live.broadcastId, text: this.form.comment}, () => {
                    this.$emit('comment', this.form.comment)
                    this.form.comment = null
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .card {
        border-radius: 15px;
    }

    .comments {
        display: flex;
        flex-direction: column-reverse;
        overflow-y: auto;
    }

    .comment {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
    }

    .comment-content {
        display: flex;
        flex-direction: column;
    }

    .comment-user-pic {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        margin-right: 10px;
    }

    .comment-user {
        font-weight: bold;
    }

    .comment.reverse {
        flex-direction: row-reverse;
        text-align: right;

        .comment-user-pic {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    .card-title {
        margin-bottom: 0;
    }
</style>