import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from "./components/Dashboard"
import Login from "./components/Login"
import Contact from "./components/pages/Contact"
import About from "./components/pages/About"
import Settings from "./components/tabs/Settings"
import Live from "./components/tabs/Live/Live"
import History from "./components/tabs/History"
import PostToIGTV from "./components/tabs/PostToIGTV"
import Watch from "./components/tabs/Watch"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      name: 'dashboard',
      redirect: '/settings',
      component: Dashboard,
      children: [
        {
          name: 'settings',
          path: 'settings',
          component: Settings,
        },
        {
          name: 'live',
          path: 'live',
          component: Live,
        },
        {
          name: 'history',
          path: 'history',
          component: History,
        },
        {
          name: 'post-to-igtv',
          path: 'post-to-igtv/:broadcastId',
          component: PostToIGTV,
        },
        {
          name: 'watch',
          path: 'watch/:playback',
          component: Watch,
        },
      ]
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    }
  ]
})

export default router