<template>
    <div>
        <div class="swiper-container" ref="mainContainer">
            <div class="swiper-wrapper">
                <div v-for="image of images" class="swiper-slide" :key="image">
                    <img :data-src="`${resourcesUrl}/${image}`" :data-thumb="`${resourcesUrl}/${image}`" class="swiper-lazy swiper-image d-none">
                    <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </div>
            </div>
            <div class="swiper-pagination" ref="pagination"></div>

            <div class="swiper-button-prev" ref="prev"></div>
            <div class="swiper-button-next" ref="next"></div>
        </div>
        <div class="swiper-container" ref="thumbsContainer">
            <div class="swiper-wrapper">
                <div v-for="image of images" class="swiper-slide swiper-slide-thumb" :key="image" :class="value === image ? 'active' : null">
                    <div class="swiper-active-border"></div>
                    <img :data-src="`${resourcesUrl}/${image}`" class="swiper-lazy swiper-image d-none">
                    <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper, { Navigation, Pagination, Thumbs, Lazy } from 'swiper'
    Swiper.use([Navigation, Pagination, Thumbs, Lazy])
    import 'swiper/swiper-bundle.css'

    export default {
        props: ['images', 'value'],
        data: function () {
            return {
                swiper: null,
                swiperThumbs: null,
            }
        },
        async mounted() {
            this.swiperThumbs = new Swiper(this.$refs.thumbsContainer, {
                spaceBetween: 0,
                slidesPerView: 5,
                freeMode: true,
                watchSlidesProgress: true,
                simulateTouch: true,

                preloadImages: false,
                watchSlidesVisibility: true,
                lazy: {
                    checkInView: true,
                    loadPrevNext: true,
                    loadPrevNextAmount: 2
                },

                on: {
                    lazyImageReady:  async (swiper, slideEl, imageEl) => {
                        imageEl.src = await this.crop(imageEl.src, 9/16)
                        imageEl.classList.remove('d-none')
                    }
                }
            })

            this.swiper = new Swiper(this.$refs.mainContainer, {
                direction: 'horizontal',
                grabCursor: true,
                loop: false,
                simulateTouch: true,

                // If we need pagination
                pagination: {
                    el: this.$refs.pagination,
                    type: 'progressbar'
                },

                // Navigation arrows
                navigation: {
                    nextEl: this.$refs.next,
                    prevEl: this.$refs.prev,
                },

                thumbs: {
                    swiper: this.swiperThumbs
                },

                preloadImages: false,
                watchSlidesVisibility: true,
                lazy: {
                    checkInView: true,
                    loadPrevNext: true,
                    loadPrevNextAmount: 2
                },

                on: {
                    lazyImageReady:  async (swiper, slideEl, imageEl) => {
                        imageEl.src = await this.crop(imageEl.src, 9/16)
                        imageEl.classList.remove('d-none')
                    },
                    slideChange: (swiper) => {
                        let index = swiper.realIndex
                        let currentSlideImg = swiper.slides[index].querySelector('img')
                        this.$emit('input', currentSlideImg.dataset.thumb)
                    },
                }
            })
        },
        beforeDestroy() {
            this.swiper.destroy()
            this.swiper = null
            this.swiperThumbs.destroy()
            this.swiperThumbs = null
        },
        methods: {
            crop(url, aspectRatio) {
                // we return a Promise that gets resolved with our canvas element
                return new Promise(resolve => {

                    // this image will hold our source image data
                    const inputImage = new Image()
                    inputImage.setAttribute('crossOrigin', '')

                    // we want to wait for our image to load
                    inputImage.onload = () => {

                        // let's store the width and height of our image
                        const inputWidth = inputImage.naturalWidth
                        const inputHeight = inputImage.naturalHeight

                        // get the aspect ratio of the input image
                        const inputImageAspectRatio = inputWidth / inputHeight

                        // if it's bigger than our target aspect ratio
                        let outputWidth = inputWidth
                        let outputHeight = inputHeight
                        if (inputImageAspectRatio > aspectRatio) {
                            outputWidth = inputHeight * aspectRatio
                        } else if (inputImageAspectRatio < aspectRatio) {
                            outputHeight = inputWidth / aspectRatio
                        }

                        // calculate the position to draw the image at
                        const outputX = (outputWidth - inputWidth) * .5
                        const outputY = (outputHeight - inputHeight) * .5

                        // create a canvas that will present the output image
                        const outputImage = document.createElement('canvas')

                        // set it to the same size as the image
                        outputImage.width = outputWidth
                        outputImage.height = outputHeight

                        // draw our image at position 0, 0 on the canvas
                        const ctx = outputImage.getContext('2d')
                        ctx.drawImage(inputImage, outputX, outputY)
                        resolve(outputImage.toDataURL())
                    }

                    inputImage.src = url
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .swiper-button-prev, .swiper-button-next {
        color: var(--primary-color);
    }

    .swiper-container {
        max-width: var(--live-thumb-width);
    }
    .swiper-slide {
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
    }
    .swiper-image[src] {
        width: 100%;
        height: auto;
    }

    .swiper-slide-thumb {
        padding: 5px;
    }
    .swiper-slide.active {
        position: relative;

        img {
            z-index: 2;
            border-radius: 5px;
        }

        .swiper-active-border {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-image: linear-gradient(to right top, #ff7652 0%, #f7be50 100%);
            border-radius: 5px;
            z-index: 1;
        }
    }
</style>

<style>
    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background-image: linear-gradient(to right top, var(--primary-color) 0%, #f7be50 100%);
    }
</style>