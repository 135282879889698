<template>
    <button class="btn btn-primary" v-clipboard="() => value" v-clipboard:success="onClipBoardSuccess" v-tooltip="{ content: 'Copied !', show: showTooltip, trigger: 'manual' }">
        <font-awesome-icon icon="copy"/>
    </button>
</template>

<script>
    export default {
        props: ['value'],
        data: function () {
            return {
                showTooltip: false
            }
        },
        methods: {
            onClipBoardSuccess() {
                this.showTooltip = true
                setTimeout(() => {
                    this.showTooltip = false
                }, 5000)
            }
        }
    }
</script>

<style scoped lang="scss">
</style>