// Breakpoints
// {
//     "xs": 0,
//     "sm": 575,
//     "md": 996,
//     "lg": 1200,
// }

// Breakpoint ranges
// {
//     "xs": [0, 574],
//     "sm": [575, 995],
//     "md": [996, 1199],
//     "lg": [1200, 9999],
// }

export default {
    install (Vue, options) {
        if (options?.breakpointRanges) {
            Vue.prototype.$breakpointRanges = options.breakpointRanges

            Vue.mixin({
                data () {
                    return {
                        breakpoints: {}
                    }
                },
                mounted () {
                    this.breakpoints = this.triggerScreenDetection()
                    window.addEventListener('resize', () => {
                        this.breakpoints = this.triggerScreenDetection()
                    })
                }
            })

            Vue.prototype.triggerScreenDetection = function () {
                const keys = Object.keys(Vue.prototype.$breakpointRanges)
                let bp = {}
                // @ts-ignore
                const screenWidth = window?.visualViewport?.width || window.innerWidth

                for (const key of keys) {
                    const minValue = Vue.prototype.$breakpointRanges[key][0]
                    const maxValue = Vue.prototype.$breakpointRanges[key][1]

                    bp[key] = screenWidth >= minValue && screenWidth <= maxValue
                }

                return bp
            }
        }
    }
}