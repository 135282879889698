<template>
    <live-mobile v-if="breakpoints.xs"
                 :socket="socket"
                 :user="user"
                 :live="live"
                 :comments="comments"
                 :viewers="viewers"
                 :likes="likes"/>
    <live-desktop v-else
                  :socket="socket"
                  :user="user"
                  :live="live"
                  :comments="comments"
                  :viewers="viewers"
                  :likes="likes"/>
</template>

<script>
    import LiveMobile from "./LiveMobile";
    import LiveDesktop from "./LiveDesktop";

    export default {
        props: ['socket', 'user', 'live', 'comments', 'viewers', 'likes'],
        components: {
            LiveDesktop,
            LiveMobile,
        },
        beforeMount() {
            if (!(this.live.broadcastId && this.live.started)) {
                this.$router.push({name: 'settings'})
            }
        },
        mounted() {
            this.socket.emit('startLongPollingDatas', {broadcastId: this.live.broadcastId})
        },
        beforeDestroy() {
            this.socket.emit('stopLongPollingDatas')
        }
    }
</script>