import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faCogs, faPlayCircle, faComments, faHistory, faHeart, faCopy, faUsers, faBars, faQuestionCircle, faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { faSmile as farSmile, faHeart as farHeart, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faGithub, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Clipboard from 'v-clipboard'
import VTooltip from 'v-tooltip'
import './registerServiceWorker'
import 'animate.css/animate.min.css'
import Breakpoints from "./plugins/Breakpoints";
import ResourcesUrl from "./plugins/ResourcesUrl";

library.add(faEye, faCogs, faPlayCircle, faComments, faHistory, faHeart, farHeart, farSmile, faCopy, faUsers, faBars, faEnvelope, faQuestionCircle, faVolumeUp, faVolumeMute, faGithub, faYoutube, faInstagram)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Clipboard)
Vue.use(VTooltip)
Vue.use(Breakpoints, {
  breakpointRanges: {
    xs: [0, 575],
    sm: [576, 767],
    md: [768, 991],
    lg: [992, 1199],
    xl: [1200, 9999999],
  }
})
Vue.use(ResourcesUrl)

window.videojs = videojs
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
