<template>
    <div class="row justify-content-center py-3 mx-1" v-if="!loader">
        <div class="col-md-12">
            <div class="card shadow">
                <div class="card-body">
                    <h5 class="card-title">Live history</h5>
                    <div class="d-flex align-items-center">
                      <toggle-button ref="liveArchiveSettingChecbox" class="mr-3" color="var(--primary-color)" v-model="liveArchiveSetting" @change="setLiveArchiveSetting" :disabled="loadingliveArchiveSetting"/>
                      <label @click="$refs.liveArchiveSettingChecbox.toggle()">
                        Save Live to archive<br>
                        <small>Automatically save your live videos to your archive for 30 days. Only you can see them.</small>
                      </label>
                    </div>
                    <div class="row mosaic mt-3">
                        <div v-for="pastLive in lives" :key="pastLive.id" class="col-md-4 mb-5">
                            <div :style="`background-image: url('${resourcesUrl}/${pastLive.broadcast.thumb}')`" class="mosaic-element d-flex shadow">
                                <div class="row m-0 w-100">
                                    <div class="col-lg-6 d-flex flex-column justify-content-center mosaic-description" style="backdrop-filter: blur(10px);">
                                        <div class="live-title">{{ pastLive.broadcast.title }}</div>
                                        <div class="live-duration">{{ getDate(pastLive.broadcast.startedAt) }}</div>
                                        <div class="live-duration" v-if="pastLive.broadcast.duration">{{ getDuration(pastLive.broadcast.duration) }}</div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-column justify-content-center">
                                        <router-link class="btn btn-outline-primary mb-4" :to="{name: 'post-to-igtv', params: {broadcastId: pastLive.broadcast.id}}" v-if="pastLive.canShareToIgtv">Post to IGTV</router-link>
                                        <router-link class="btn btn-outline-primary" :to="{name: 'watch', params: {playback: pastLive.broadcast.playback}}" v-if="pastLive.broadcast.playback">Watch</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center h-100">
        <loader size="100px" color="#ff7652"></loader>
    </div>
</template>

<script>
    import {DateTime, Duration} from "luxon"
    import Loader from 'vue-spinner/src/MoonLoader'
    import { ToggleButton } from 'vue-js-toggle-button'

    export default {
        props: ['socket', 'user', 'live', 'comments', 'viewers', 'likes'],
        components: {
            Loader,
            ToggleButton
        },
        data: function () {
            return {
                lives: [],
                loader: true,
                liveArchiveSetting: false,
                loadingliveArchiveSetting: true
            }
        },
        mounted() {
            this.socket.emit('getLives', (lives) => {
                this.lives = lives
                this.loader = false
            })
            this.socket.emit('getLiveArchiveSetting', (setting) => {
                this.liveArchiveSetting = setting
                this.loadingliveArchiveSetting = false
            })
        },
        methods: {
            getDuration(s) {
                return Duration.fromMillis(s * 1000).toFormat('mm:ss')
            },
            getDate(ms) {
                return DateTime.fromMillis(ms*1000).toLocaleString(DateTime.DATETIME_SHORT)
            },
            setLiveArchiveSetting() {
                this.loadingliveArchiveSetting = true
                this.socket.emit('setLiveArchiveSetting', {liveArchiveSetting: this.liveArchiveSetting}, (setting) => {
                    this.liveArchiveSetting = setting
                    this.loadingliveArchiveSetting = false
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .card {
        border-radius: 15px;
    }

    .mosaic {
        .mosaic-element {
            background-size: cover;
            background-position: center;
            min-height: 240px;
            border-radius: 10px;

            .mosaic-description {
                backdrop-filter: blur(10px);
                background-color: rgba(0,0,0,0.25);
                border-radius: 10px;
            }

            img {
                object-fit: cover;
                border-radius: 10px;
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
