<template>
    <div class="h-100 w-100">
        <video class="h-100 w-100" :src="`${resourcesUrl}/${playback}`" v-if="playback" controls autoplay></video>
    </div>
</template>

<script>
    export default {
        props: ['socket', 'user', 'live', 'comments', 'viewers', 'likes'],
        data: function () {
            return {
                playback: null,
            }
        },
        mounted() {
            this.playback = this.$route.params.playback
        }
    }
</script>