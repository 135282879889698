<template>
    <div class="preview-container">
        <div v-show="showPlayer" class="video-container">
            <video ref="video"></video>

            <div class="viewers" v-if="viewers.length">
                <font-awesome-icon icon="eye"/>
                {{ viewers.length }}
            </div>

            <div class="sound" @click="toggleVolume">
                <font-awesome-icon :icon="muted ? 'volume-mute' : 'volume-up'"/>
            </div>

            <div class="likes" v-if="likes.total">
                <font-awesome-icon v-if="processLikes" icon="heart" color="#fff"/>
                <font-awesome-icon v-else :icon="['far', 'heart']"/>
                {{ likes.total }}

                <div class="floating floating-like" v-for="index in floatingLikes" :key="index" :style="`--delay: ${Math.random()}s; --slide: ${getRandomInt(5, 20)}px; --offset: ${getRandomInt(-10, 30)}px;`">
                    <font-awesome-icon icon="heart" color="#fff"/>
                </div>

                <div class="floating floating-liker" v-for="floatingLiker of floatingLikers" :key="floatingLiker.id" :style="`--delay: ${Math.random()}s; --slide: ${getRandomInt(5, 20)}px; --offset: ${getRandomInt(-10, 30)}px;`">
                    <img :src="floatingLiker.picture">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RxPlayer from "rx-player"

    export default {
        props: ['live', 'viewers', 'likes'],
        data: function () {
            return {
                player: null,
                showPlayer: false,
                processLikes: false,
                floatingLikes: 0,
                floatingLikers: [],
                muted: false
            }
        },
        watch: {
            likes(val) {
                this.processLikes = true

                let floatingLikes = 0
                let floatingLikers = []
                this.floatingLikers = floatingLikers
                this.floatingLikes = floatingLikes

                for (let liker of val.likers) {
                    floatingLikers.push(liker)
                    floatingLikes += liker.count
                }

                this.floatingLikers = floatingLikers
                this.floatingLikes = floatingLikes

                setTimeout(() => {
                    this.processLikes = false
                }, 4000)
            },
        },
        mounted() {
            this.setupPlayer()
            this.setupPlayerSrc()
        },
        beforeDestroy() {
            this.player.dispose()
            this.player = null
        },
        methods: {
            setupPlayer() {
                this.player = new RxPlayer({
                    videoElement: this.$refs.video
                })
            },
            setupPlayerSrc() {
                if (this.player && this.live.dashPlaybackUrl) {
                    this.player.loadVideo({
                        url: this.resourcesUrl + '/' + this.live.dashPlaybackUrl,
                        transport: "dash",
                        autoPlay: true
                    })
                    this.muted = this.player.isMute()
                    this.showPlayer = true
                }
            },
            getRandomInt(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min)) + min
            },
            toggleVolume() {
                if (this.player) {
                    if (this.player.isMute()) {
                        this.player.unMute()
                    } else {
                        this.player.mute()
                    }

                    this.muted = this.player.isMute()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .preview-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .video-container {
        position: relative;
        display: inline-flex;
        height: 100%;
        width: 100%;

        video {
            max-height: 100%;
            width: 100%;
            border-radius: 15px;
            object-fit: cover;
        }

        .viewers {
            position: absolute;
            top: 15px;
            right: 15px;
            background-color: rgba(255, 255, 255, .4);
            backdrop-filter: blur(10px);
            border-radius: 5px;
            padding: 5px 10px;
        }

        .sound {
            position: absolute;
            top: 15px;
            left: 15px;
            background-color: rgba(255, 255, 255, .4);
            backdrop-filter: blur(10px);
            border-radius: 5px;
            padding: 5px 10px;
            cursor: pointer;
        }

        .likes {
            position: absolute;
            bottom: 15px;
            left: 15px;
            background-color: rgba(255, 255, 255, .4);
            backdrop-filter: blur(10px);
            border-radius: 5px;
            padding: 5px 10px;

            .floating-like {
                position: absolute;
                width: 16px;
                height: 16px;
                display: flex;
                left: calc(50% - 8px);
                top: calc(50% - 8px);
                z-index: 100;
            }

            .floating-liker {
                position: absolute;
                width: 32px;
                height: 32px;
                display: flex;
                left: calc(50% - 16px);
                top: calc(50% - 16px);
                z-index: 50;

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                }
            }

            .floating {
                animation:
                        slideUp 3s ease-in-out var(--delay),
                        slideWays 1s ease-in-out infinite alternate var(--delay);
                opacity: 0;
                left: var(--offset);

                @keyframes slideUp {
                    0% {
                        opacity: 0;
                    }
                    25% {
                        opacity: 1;
                    }
                    75% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                        transform: translateY(-200px) scale(1.2);
                    }
                }

                @keyframes slideWays {
                    0% {
                        margin-left: -(var(--slide));
                    }
                    50%{
                        margin-left: var(--slide);
                    }
                    100% {
                        margin-left: -(var(--slide));
                    }
                }
            }
        }
    }
</style>
