<template>
    <div>
        <div class="input-group" v-if="!type">
            <input ref="input" type="text" class="form-control" :placeholder="placeholder" :value="value"
                   @input="$emit('input', $event.target.value)" @keyup.enter="onSubmit" :disabled="loading" autofocus>

            <div class="input-group-append">
                <span class="input-group-text emoji-button" @click="onEmojiClick" :class="loading ? 'disabled' : null"><font-awesome-icon
                        :icon="['far', 'smile']"></font-awesome-icon></span>

                <button class="btn btn-primary d-flex flex-row align-items-center justify-content-center"
                        @click="onSubmit" :disabled="loading" v-if="handleSubmit">
                    {{ submitText }}
                    <loader color="#fff" size="20px" class="ml-2" v-if="loading"></loader>
                </button>
            </div>
        </div>

        <div class="input-group" v-if="type === 'textarea'">
            <textarea ref="input" type="text" class="form-control" :placeholder="placeholder" :value="value" :rows="rows ? rows : 1"
                      @input="$emit('input', $event.target.value)" @keyup.enter="onSubmit" :disabled="loading" autofocus></textarea>

            <div class="input-group-append">
                <span class="input-group-text emoji-button" @click="onEmojiClick" :class="loading ? 'disabled' : null"><font-awesome-icon
                        :icon="['far', 'smile']"></font-awesome-icon></span>

                <button class="btn btn-primary d-flex flex-row align-items-center justify-content-center"
                        @click="onSubmit" :disabled="loading" v-if="handleSubmit">
                    {{ submitText }}
                    <loader color="#fff" size="20px" class="ml-2" v-if="loading"></loader>
                </button>
            </div>
        </div>

        <v-emoji-picker class="emoji-picker" v-if="showEmojiPicker"
                        :emoji-with-border="false"
                        @select="selectEmoji"/>
    </div>
</template>

<script>
    import Loader from 'vue-spinner/src/MoonLoader'
    import {VEmojiPicker} from 'v-emoji-picker'

    export default {
        props: ['placeholder', 'loading', 'value', 'handleSubmit', 'submitText', 'type', 'rows'],
        components: {
            Loader,
            VEmojiPicker
        },
        data: function () {
            return {
                showEmojiPicker: false
            }
        },
        methods: {
            onSubmit() {
                if (this.handleSubmit) {
                    this.$emit('submit')
                }
            },
            onEmojiClick() {
                if (!this.loading) {
                    this.showEmojiPicker = !this.showEmojiPicker
                }
            },
            selectEmoji(emoji) {
                this.typeInTextarea(emoji.data)
                this.showEmojiPicker = false
            },
            typeInTextarea(newText) {
                this.$refs.input.setRangeText(newText, this.$refs.input.selectionStart, this.$refs.input.selectionEnd, 'end')
                this.$emit('input', this.$refs.input.value)
            }
        }
    }
</script>

<style scoped lang="scss">
    .emoji-picker {
        width: 100% !important;
    }

    .emoji-button {
        cursor: pointer;
        background-color: #fff;
        border-left: none;
    }

    .emoji-button.disabled {
        background-color: #e9ecef;
    }

    input[type="text"], textarea {
        border-right: none;
    }
</style>


<style lang="scss">
    .grid-emojis {
        grid-template-columns: none;
        display: flex !important;
        flex-wrap: wrap;
        justify-content: center;
    }
</style>