<template>
    <div class="h-100">
        <div class="row justify-content-center py-3 mx-1">
            <div class="col-lg-4">
                <div class="card shadow p-md-4">
                    <div class="card-body text-justify">
                        <h5 class="card-title">What is InstaLive&#8239;?</h5>
                        <p class="card-text">
                            InstaLive is a web-application that allows you to stream on your Instagram account with your favorite streaming software like OBS (the most common and popular) or any other application/device that can stream to an RTMP server (a GoPro for example).<br>
                            <br>
                            We act like your Instagram application and reproduce what the application does. But as the Instagram application is really user-friendly, designed to be used on a smartphone, it doesn't give you all the informations to be able to stream without it.<br>
                            Because we're using an open source Instagram API, we can access to theses missing informations and share it with you.
                            This is why InstaLive needs you to login with your personal Instagram credentials.<br>
                            We don’t store your Instagram credentials, we don't even have a database, it's just a gateway between you and Instagram itself.<br>
                            <br>
                            InstaLive is not affiliated with, endorsed or certified by Instagram.
                        </p>

                        <hr class="my-5">

                        <h5 class="card-title">What InstaLive offers&#8239;?</h5>
                        <p class="card-text">
                            We didn't invent anything, there are already some websites or applications that allows you to stream on Instagram. But theses services have limitations : it's not free, you need to install a software on your computer and you can't interact with your community during the live.<br>
                            With InstaLive, you have nothing to install, use our website on whatever you want, a computer or a smartphone, you just need a modern web browser (Sorry Internet Explorer, I'm not sure that our website works with you). During the live, you can see, read and send comments with your viewers and followers, in real time.<br>And of course it's free.
                        </p>

                        <hr class="my-5">

                        <h5 class="card-title">How it works ?</h5>
                        <p class="card-text">
                            Simple, in 3 steps :<br>
                            - Log into InstaLive with your Instagram credentials<br>
                            - Get your RTMP URL and stream key and copy them into your streaming software/service<br>
                            - Start your live with a single button and entertain your followers!
                        </p>

                        <hr class="my-5">

                        <h5 class="card-title">Why is it free and what's your profit&#8239;?</h5>
                        <p class="card-text">
                            It's simple : I personally needed it so I created it to use it. Why wouldn't I share this with others&#8239;?<br>
                            See "Who we are"!
                        </p>

                        <hr class="my-5">

                        <h5 class="card-title">Who we are&#8239;?</h5>
                        <p class="card-text">
                            Who I am&#8239;?<br>
                            My name is Olivier Mouren, I'm a french developer, that's my full time job. During my spare time, I'm making some drone videos, I often post videos on my Instagram because it's really direct with my friends and family. One day, I wanted to try to make some drone shots in live, just for fun. But Instagram didn't allow me to do that, so I did some research and started to code an application to give me the ability to do that&#8239;!<br>
                            Voilà&#8239;! InstaLive is born&#8239;!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>
</style>
