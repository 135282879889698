<template>
    <div class="row justify-content-center py-3 mx-1 h-100">
        <div class="col-lg-4 col-md-6 col-sm-6 pb-3 h-100">
            <preview :live="live" :viewers="viewers" :likes="likes"/>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 pb-3 h-100">
            <comments :socket="socket"
                      :user="user"
                      :live="live"
                      :comments="comments"
                      @comment="onCommentSent"/>
        </div>

        <div class="col-lg-4 col-md-12 pb-3 h-100">
            <viewers-likes :viewers="viewers" :likes="likes"/>
        </div>
    </div>
</template>

<script>
    import Preview from "../../PreviewRxPlayer"
    import Comments from "../../Comments"
    import ViewersLikes from "../../ViewersLikes"

    export default {
        props: ['socket', 'user', 'live', 'comments', 'viewers', 'likes'],
        components: {
            ViewersLikes,
            Comments,
            Preview,
        },
        methods: {
            onCommentSent(comment) {
                this.$emit('commentSent', comment)
            }
        }
    }
</script>

<style scoped lang="scss">
    .card {
        border-radius: 15px;
    }
</style>