<template>
    <div style="position:relative;" class="h-100">
        <transition
                :mode="transitionMode"
                :enter-active-class="`animate__animated_custom animate__animated animate__faster ${transitionEnter}`"
                :leave-active-class="`animate__animated_custom animate__animated animate__faster ${transitionLeave}`">
            <router-view v-if="!loadingResume"
                         :socket="socket"
                         :user="user"
                         :live="live"
                         :comments="comments"
                         :viewers="viewers"
                         :likes="likes"
                         @commentSent="onCommentSent"
                         @saveLastBroadcast="saveLastBroadcast"
                         @liveUpdated="onLiveUpdated"/>
        </transition>
    </div>
</template>

<script>
    import {v4 as uuidv4} from 'uuid'

    export default {
        props: ['socket', 'user'],
        beforeMount() {
            if (!this.user) {
                this.$router.push({name: 'login'})
            }
        },
        data: function () {
            return {
                live: {
                    title: 'Live by InstaLive',
                    broadcastId: null,
                    uploadUrl: null,
                    streamKey: null,
                    streamUrl: null,
                    dashPlaybackUrl: null,
                    dashAbrPlaybackUrl: null,
                    started: false,
                },
                loadingResume: true,
                comments: [],
                viewers: [],
                likes: {
                    likers: [],
                    total: 0
                },
                tabsRoutes: {
                    'settings': 1,
                    'live': 2,
                    'history': 3
                },
                transitionMode: null,
                transitionEnter: null,
                transitionLeave: null,
            }
        },
        watch: {
            '$route' (to, from) {
                if (this.tabsRoutes[to.name] && this.tabsRoutes[from.name]) {
                    let toPosition = this.tabsRoutes[to.name]
                    let fromPosition = this.tabsRoutes[from.name]

                    this.transitionMode = null
                    this.transitionEnter = fromPosition < toPosition ? 'animate__fadeInRight' : 'animate__fadeInLeft'
                    this.transitionLeave = fromPosition < toPosition ? 'animate__fadeOutLeft' : 'animate__fadeOutRight'
                } else {
                    this.transitionMode = 'out-in'
                    this.transitionEnter = 'animate__fadeIn'
                    this.transitionLeave = 'animate__fadeOut'
                }
            }
        },
        computed: {
            lastBroadcast: {
                get() {
                    let broadcasts = localStorage.lastBroadcast ? JSON.parse(localStorage.lastBroadcast) : []
                    let userLastBroadcast = broadcasts.find(userBroadcasts => userBroadcasts.username === this.user.username)

                    if (!userLastBroadcast) {
                        userLastBroadcast = {
                            username: this.user.username,
                            live: null
                        }
                        broadcasts.push(userLastBroadcast)
                    }

                    return userLastBroadcast.live
                },
                set(newValue) {
                    let broadcasts = localStorage.lastBroadcast ? JSON.parse(localStorage.lastBroadcast) : []
                    let userLastBroadcast = broadcasts.find(userBroadcasts => userBroadcasts.username === this.user.username)

                    if (!userLastBroadcast) {
                        userLastBroadcast = {
                            username: this.user.username,
                            live: newValue
                        }
                        broadcasts.push(userLastBroadcast)
                    } else {
                        userLastBroadcast.live = newValue
                    }

                    localStorage.lastBroadcast = JSON.stringify(broadcasts)
                }
            }
        },
        mounted() {
            if (this.lastBroadcast) {
                this.socket.emit('canResumeLive', {broadcastId: this.lastBroadcast.broadcastId}, (canResume) => {
                    if (canResume) {
                        this.onLiveUpdated(this.lastBroadcast)
                    } else {
                        this.saveLastBroadcast(null)
                        this.onLiveUpdated({
                            title: 'Live by InstaLive',
                            broadcastId: null,
                            uploadUrl: null,
                            streamKey: null,
                            streamUrl: null,
                            dashPlaybackUrl: null,
                            dashAbrPlaybackUrl: null,
                            started: false,
                        })
                    }
                    this.loadingResume = false
                })
            } else {
                this.loadingResume = false
            }

            this.socket.on('comments', (data, res) => {
                this.comments = this.comments.filter(comment => !comment.tmp).concat(data)
                res()
            })

            this.socket.on('viewers', (data, res) => {
                this.viewers = data
                res()
            })

            this.socket.on('likes', (data, res) => {
                this.likes = data
                res()
            })
        },
        methods: {
            saveLastBroadcast(live) {
                this.lastBroadcast = live
            },
            onCommentSent(comment) {
                this.comments.push({
                    id: uuidv4(),
                    user: {
                        username: this.user.username,
                        picture: this.user.picture
                    },
                    text: comment,
                    createdAt: Date.now() / 1000,
                    tmp: true,
                })
            },
            onLiveUpdated(live) {
                this.live = live
                this.$emit('liveUpdated', {...live})
            }
        }
    }
</script>

<style>
    .animate__animated_custom {
        position: absolute !important;
        top: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
    }
</style>