import { render, staticRenderFns } from "./SwiperWithThumbs.vue?vue&type=template&id=0c266f53&scoped=true&"
import script from "./SwiperWithThumbs.vue?vue&type=script&lang=js&"
export * from "./SwiperWithThumbs.vue?vue&type=script&lang=js&"
import style0 from "./SwiperWithThumbs.vue?vue&type=style&index=0&id=0c266f53&scoped=true&lang=scss&"
import style1 from "./SwiperWithThumbs.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c266f53",
  null
  
)

export default component.exports