<template>
    <div class="row flex-column justify-content-center align-items-center h-100">
        <h3 class="text-center text-primary">A simple way to be live on Instagram with your favorite streaming software</h3>

        <div class="col-lg-4">
            <div class="card shadow p-md-4">
                <div class="card-body text-center align-items-center d-flex flex-column" v-if="nav === 'login'">
                    <div class="mb-2">Please enter your Instagram credentials</div>

                    <input id="username" class="form-control" :class="error ? 'is-invalid' : null" type="text"
                           v-model="form.username" placeholder="Username" name="username" required autofocus>
                    <input class="form-control" :class="error ? 'is-invalid' : null" type="password"
                           v-model="form.password" placeholder="Password" name="password" required>
                    <div v-if="error" class="form-group text-danger">
                        The password you entered is incorrect. Please try again.
                    </div>

                    <button @click="login"
                            class="btn btn-primary d-flex flex-row align-items-center justify-content-center btn-block"
                            :disabled="loading">
                        Log in
                        <loader color="#fff" class="ml-2" size="20px" v-if="loading"/>
                    </button>

                    <small class="form-text text-muted mt-5">Your privacy is our top priority. We don't store your
                        Instagram username and password, and we will never share your credentials with anyone other
                        than Instagram itself.</small>
                </div>

                <div class="card-body text-center align-items-center d-flex flex-column" v-if="nav === 'challenge'">
                    <div class="mb-4">
                        You should receive a code by email or SMS, please enter this code here
                    </div>

                    <input class="form-control text-center" :class="error ? 'is-invalid' : null" type="text"
                           v-model="form.securityCode" placeholder="Code" required autofocus>

                    <div class="d-flex flex-row justify-content-center align-items-center flex-wrap mt-4">
                        <button @click="cancelChallenge" class="btn btn-outline-danger mx-2" :disabled="loading">
                            <span>Cancel</span>
                        </button>
                        <button @click="sendSecurityCode"
                                class="btn btn-primary d-flex flex-row align-items-center justify-content-center mx-2"
                                :disabled="loading">
                            Validate
                            <loader color="#fff" class="ml-2" size="20px" v-if="loading"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from 'vue-spinner/src/MoonLoader'

    export default {
        props: ['socket', 'user'],
        components: {
            Loader
        },
        data: function () {
            return {
                form: {
                    username: null,
                    password: null,
                    rememberMe: true,
                    securityCode: null
                },
                loading: false,
                error: false,
                nav: 'login'
            }
        },
        beforeMount() {
            if (this.user) {
                this.$router.push({name: 'dashboard'})
            }
        },
        methods: {
            login() {
                this.loading = true
                this.error = false
                this.socket.emit('login', {username: this.form.username, password: this.form.password}, (user) => {
                    if (user.error) {
                        this.error = true
                        this.loading = false

                        if (user.challenge) {
                            this.nav = 'challenge'
                        }
                    } else {
                        if (this.form.rememberMe) {
                            localStorage.state = JSON.stringify(user.state)
                        }

                        this.$emit('userUpdated', user)
                    }
                })
            },
            sendSecurityCode() {
                this.loading = true
                this.error = false
                this.socket.emit('securityCode', {code: this.form.securityCode}, (user) => {
                    if (user.error) {
                        this.error = true
                        this.loading = false
                    } else {
                        localStorage.state = JSON.stringify(user.state)

                        this.$emit('userUpdated', user)
                    }
                })
            },
            cancelChallenge() {
                this.loading = false
                this.error = false
                this.form = {
                    username: null,
                    password: null,
                    rememberMe: false,
                    securityCode: null
                }
                this.nav = 'login'
            }
        }
    }
</script>

<style scoped>
    .form-control {
        padding: 10px;
        font-size: 16px;
        max-width: 350px;
    }

    button {
        max-width: 350px;
    }

    #username {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .text-primary {
        color: #ff7652 !important;
    }

    small {
        max-width: 600px;
    }
</style>
