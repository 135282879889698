<template>
    <div class="row justify-content-center py-3 mx-1" v-if="broadcast">
        <div class="col-xl-3">
            <div class="card shadow p-md-4">
                <div class="card-body">
                    <h5 class="card-title text-center">
                        Post a live replay on IGTV
                    </h5>

                    <div>
                        <div class="form-group">
                            <label>
                                Title
                                <emoji-text-input v-model="form.title" :loading="loading"/>
                            </label>
                        </div>
                        <div class="form-group">
                            <label>
                                Description
                                <emoji-text-input v-model="form.description" :loading="loading" type="textarea" rows="3"/>
                            </label>
                        </div>

                        <div class="form-group">
                            Thumbnail
                            <swiper-with-thumbs :images="broadcast.thumbnails" v-model="form.thumb"/>
                        </div>

                        <button @click="postToIGTV"
                                class="btn btn-primary d-flex flex-row align-items-center justify-content-center btn-block"
                                :disabled="loading">
                            Post to IGTV
                            <loader color="#fff" class="ml-2" size="20px" v-if="loading"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center h-100">
        <loader size="100px" color="#ff7652"></loader>
    </div>
</template>

<script>
    import Loader from 'vue-spinner/src/MoonLoader'
    import EmojiTextInput from "../EmojiTextInput"
    import SwiperWithThumbs from "../swiper/SwiperWithThumbs";

    export default {
        props: ['socket', 'user', 'live', 'comments', 'viewers', 'likes'],
        components: {
            SwiperWithThumbs,
            EmojiTextInput,
            Loader
        },
        data: function () {
            return {
                broadcast: null,
                form: {
                    title: null,
                    description: null,
                    thumb: null
                },
                loading: false,
            }
        },
        mounted() {
            this.socket.emit('getBroadcast', {broadcastId: this.$route.params.broadcastId}, (broadcast) => {
                this.broadcast = broadcast
                this.form.title = broadcast.title
                this.form.thumb = broadcast.thumbnails[0]
            })
        },
        methods: {
            postToIGTV() {
                this.loading = true
                this.socket.emit('postToIGTV', {broadcastId: this.broadcast.id, title: this.form.title, description: this.form.description, thumb: this.form.thumb}, () => {
                    this.loading = false
                    this.$router.go(-1)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    label {
        width: 100%;
    }
</style>