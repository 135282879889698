<template>
    <div v-if="socket && connected && ready" class="app d-flex flex-column h-100">
        <nav class="header navbar fixed-top navbar-expand-sm shadow-xl"
             :class="user ? null : 'justify-content-between'">
            <a href="/"><img src="/logo.png" class="logo" alt="InstaLive"></a>

            <div v-if="user" class="navbar-toggler" type="button" @click="showSideMenu = true">
                <div class="user">
                    <div class="profile-pic-block">
                        <div class="profile-pic-border"></div>
                        <img :src="`${resourcesUrl}/${user.picture}`" class="profile-pic">
                    </div>
                </div>
            </div>
            <div v-else class="navbar-toggler" type="button" @click="showSideMenu = true">
                <font-awesome-icon icon="bars"/>
            </div>

            <div v-if="user" class="navbar-collapse collapse justify-content-center">
                <ul class="navbar-nav">
                    <li class="nav-item" :class="currentRouteName === 'settings' ? 'active' : null">
                        <div class="nav-item-border"></div>
                        <router-link class="btn btn-block" :to="{name: 'settings'}">
                            <font-awesome-icon icon="cogs" class="nav-icon"/>
                            Settings
                        </router-link>
                    </li>

                    <li class="nav-item" :class="currentRouteName === 'live' ? 'active' : null">
                        <div class="nav-item-border"></div>
                        <router-link class="btn btn-block" :to="{name: 'live'}"
                                     v-if="live && live.broadcastId && live.started">
                            <font-awesome-icon icon="play-circle" class="nav-icon"/>
                            Live
                        </router-link>
                    </li>

                    <li class="nav-item" :class="currentRouteName === 'history' ? 'active' : null">
                        <div class="nav-item-border"></div>
                        <router-link class="btn btn-block" :to="{name: 'history'}">
                            <font-awesome-icon icon="history" class="nav-icon"/>
                            History
                        </router-link>
                    </li>

                    <li class="nav-item d-block d-sm-none mt-2">
                        <button class="btn btn-primary btn-block" @click="logout">Log out</button>
                    </li>
                </ul>
            </div>

            <div v-if="user" class="user d-none d-sm-block" type="button" @click="showSideMenu = true">
                <div class="profile-pic-block">
                    <div class="profile-pic-border"></div>
                    <img :src="`${resourcesUrl}/${user.picture}`" class="profile-pic">
                </div>
            </div>
            <div v-else class="user d-none d-sm-block" type="button" @click="showSideMenu = true">
                <font-awesome-icon icon="bars"/>
            </div>
        </nav>

        <transition
                enter-active-class="animate__animated_custom animate__animated animate__faster animate__slideInRight"
                leave-active-class="animate__animated_custom animate__animated animate__faster animate__slideOutRight">
            <div class="sidemenu-container" v-if="showSideMenu" @click="showSideMenu = false">
                <div class="sidemenu shadow-xl">
                    <div v-if="user" class="d-flex flex-column align-items-center justify-content-center pb-4 pt-4 sidemenu-user">
                        <div class="profile-pic-block">
                            <div class="profile-pic-border shadow"></div>
                            <img :src="`${resourcesUrl}/${user.picture}`" class="profile-pic">
                        </div>

                        <span>Logged as</span>
                        <span class="profile-username">{{ user.username }}</span>

                        <button class="btn btn-outline-primary mt-3" @click="logout">Log out</button>

                        <div class="sidemenu-user-border"></div>
                    </div>
                    <div v-else class="d-flex flex-column align-items-center justify-content-center pb-4 pt-4 sidemenu-user">
                        <router-link :to="{name: 'login'}" class="btn btn-outline-primary">Log in</router-link>

                        <div class="sidemenu-user-border"></div>
                    </div>

                    <ul class="navbar-nav sidemenu-nav" v-if="user">
                        <li class="nav-item" :class="currentRouteName === 'settings' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'settings'}">
                                <font-awesome-icon icon="cogs" class="nav-icon"/>
                                <span>Settings</span>
                            </router-link>
                        </li>

                        <li class="nav-item" :class="currentRouteName === 'live' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'live'}"
                                         v-if="live && live.broadcastId && live.started">
                                <font-awesome-icon icon="play-circle" class="nav-icon"/>
                                <span>Live</span>
                            </router-link>
                        </li>

                        <li class="nav-item" :class="currentRouteName === 'history' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'history'}">
                                <font-awesome-icon icon="history" class="nav-icon"/>
                                <span>History</span>
                            </router-link>
                        </li>
                    </ul>

                    <hr v-if="user">

                    <ul class="navbar-nav sidemenu-nav">
                        <li class="nav-item" :class="currentRouteName === 'about' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'about'}">
                                <font-awesome-icon icon="question-circle" class="nav-icon"/>
                                <span>About</span>
                            </router-link>
                        </li>

                        <li class="nav-item" :class="currentRouteName === 'contact' ? 'active' : null">
                            <router-link class="btn btn-block" :to="{name: 'contact'}">
                                <font-awesome-icon :icon="['far', 'envelope']" class="nav-icon"/>
                                <span>Contact</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>

        <main role="main" class="container-fluid flex-grow-1" style="overflow-x: hidden;">
            <router-view
                    :socket="socket"
                    :user="user"
                    @userUpdated="onUserUpdated"
                    @liveUpdated="onLiveUpdated"
            />
        </main>

        <footer class="footer d-flex flex-column flex-md-row align-items-center justify-content-between p-3 px-md-4" v-if="false">
            <h5 class="my-0 font-weight-normal d-none d-md-block"><img src="/logo.png" class="logo" alt="Instalive"></h5>
            <div>InstaLive © 2020</div>
            <div class="d-flex flex-md-row justify-content-center flex-wrap">
                <router-link :to="{name: 'contact'}" class="btn btn-link">Contact</router-link>
                <router-link :to="{name: 'about'}" class="btn btn-link">About</router-link>
            </div>
        </footer>
    </div>
</template>

<script>
    import {io} from 'socket.io-client';

    export default {
        name: 'App',
        components: {},
        data: function () {
            return {
                socket: null,
                connected: false,
                user: null,
                ready: false,
                loading: false,
                live: null,
                showSideMenu: false
            }
        },
        mounted() {
            this.socket = io(process.env.VUE_APP_SOCKET_URL)
            this.socket.on('connect', () => {
                this.connected = true

                if (localStorage.state) {
                    this.socket.emit('loginFromState', {state: localStorage.state}, (user) => {
                        if (user) {
                            this.user = user
                        } else {
                            localStorage.removeItem('state')
                            this.onUserUpdated(null)
                        }
                        this.ready = true
                    })
                } else {
                    if (this.$router.currentRoute.name !== 'login') {
                        this.$router.push({name: 'login'})
                    }
                    this.ready = true
                }
            })

            this.socket.on('disconnect', () => {
                this.connected = false
                this.ready = false
                this.onUserUpdated(null)
            })
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        methods: {
            onUserUpdated(user) {
                this.user = user
                if (!user) {
                    if (this.$router.currentRoute.name !== 'login') {
                        this.$router.push({name: 'login'})
                    }
                } else {
                    this.$router.push({name: 'dashboard'})
                }
            },
            logout() {
                this.loadingLogout = true
                this.socket.emit('logout', () => {
                    localStorage.removeItem('state')
                    this.onUserUpdated(null)
                })
            },
            onLiveUpdated(live) {
                this.live = live
            }
        }
    }
</script>

<style lang="scss">
    :root{
        --bg-color: #0e0e10;
        --primary-color: #ff7652;
        --primary-light-color: #f7be50;
        --text-color: #efeff1;
        --live-thumb-width: calc(1080px/4);
        --live-thumb-height: calc(1920px/4);
    }

    html, body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        height: 100vh;
        color: var(--text-color) !important;
        overflow-x: hidden;
    }

    .container-fluid {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .btn-primary {
        transition: all 1s cubic-bezier(.25, .8, .25, 1) !important;
        background-size: 200% auto;
        background-image: linear-gradient(to right, var(--primary-color) 0%, var(--primary-light-color) 50%, var(--primary-color) 100%);
        background-position: left center;
        border: 0 !important;
    }

    .btn-primary:hover {
        background-position: right center;
    }

    .btn-outline-primary {
        position: relative;
        color: #fff !important;
        border-color: var(--primary-color) !important;
        backdrop-filter: blur(10px);
        background-color: inherit !important;
    }

    .btn-outline-primary::after {
        transition: all 1s cubic-bezier(.25, .8, .25, 1) !important;
        content: "";
        background-image: linear-gradient(to right, var(--primary-color) 0%, var(--primary-light-color) 50%, var(--primary-color) 100%);
        background-size: 200% auto;
        background-position: left center;
        border-radius: 3px;
        opacity: 0.1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }

    .btn-outline-primary:hover {
        background-color: inherit !important;
        color: #fff !important;
    }

    .btn-outline-primary:hover::after {
        background-position: right center;
        opacity: 1;
    }

    .card {
        background-color: #18181b !important;
        border-radius: 20px;
    }

    .list-group-item {
        background: none !important;
        border-color: hsla(0, 0.0%, 100%, 0.1) !important;
    }

    hr {
        border-color: hsla(0, 0.0%, 100%, 0.1) !important;
    }

    .shadow-xl {
        box-shadow: 0 0 2rem rgba(0,0,0,.7)!important;
    }

    .tooltip {
        display: block !important;
        z-index: 10000;

        .tooltip-inner {
            background: black;
            color: white;
            border-radius: 16px;
            padding: 5px 10px 4px;
        }

        .tooltip-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            margin: 5px;
            border-color: black;
            z-index: 1;
        }

        &[x-placement^="top"] {
            margin-bottom: 5px;

            .tooltip-arrow {
                border-width: 5px 5px 0 5px;
                border-left-color: transparent !important;
                border-right-color: transparent !important;
                border-bottom-color: transparent !important;
                bottom: -5px;
                left: calc(50% - 5px);
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &[x-placement^="bottom"] {
            margin-top: 5px;

            .tooltip-arrow {
                border-width: 0 5px 5px 5px;
                border-left-color: transparent !important;
                border-right-color: transparent !important;
                border-top-color: transparent !important;
                top: -5px;
                left: calc(50% - 5px);
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &[x-placement^="right"] {
            margin-left: 5px;

            .tooltip-arrow {
                border-width: 5px 5px 5px 0;
                border-left-color: transparent !important;
                border-top-color: transparent !important;
                border-bottom-color: transparent !important;
                left: -5px;
                top: calc(50% - 5px);
                margin-left: 0;
                margin-right: 0;
            }
        }

        &[x-placement^="left"] {
            margin-right: 5px;

            .tooltip-arrow {
                border-width: 5px 0 5px 5px;
                border-top-color: transparent !important;
                border-right-color: transparent !important;
                border-bottom-color: transparent !important;
                right: -5px;
                top: calc(50% - 5px);
                margin-left: 0;
                margin-right: 0;
            }
        }

        &.popover {
            $color: #f9f9f9;

            .popover-inner {
                background: $color;
                color: black;
                padding: 24px;
                border-radius: 5px;
                box-shadow: 0 5px 30px rgba(black, .1);
            }

            .popover-arrow {
                border-color: $color;
            }
        }

        &[aria-hidden='true'] {
            visibility: hidden;
            opacity: 0;
            transition: opacity .15s, visibility .15s;
        }

        &[aria-hidden='false'] {
            visibility: visible;
            opacity: 1;
            transition: opacity .15s;
        }
    }
</style>

<style scoped lang="scss">
    .app {
        .logo {
            max-height: 30px;
        }

        .header {
            background-color: #18181b;
            min-height: 54px;

            .navbar-collapse {
                margin-top: 5px;
            }

            .navbar-toggler {
                padding: 0;
            }

            .profile-pic-block {
                margin: 2px;
                width: 32px;
                height: 32px;
                position: relative;

                .profile-pic {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    width: 100%;
                    border-radius: 100%;
                    z-index: 2;
                }

                .profile-pic-border {
                    position: absolute;
                    border-radius: 100%;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: -2px;
                    background-image: linear-gradient(to right top, var(--primary-color) 0%, #f7be50 100%);
                    z-index: 1;
                }
            }

            .nav-item a {
                color: #efeff1;
            }

            .nav-item.active {
                position: relative;

                a {
                    position: relative;
                    z-index: 2;
                    background-color: #18181b;
                    border-radius: 0;
                }

                .nav-item-border {
                    position: absolute;
                    transition: all 1s cubic-bezier(.25, .8, .25, 1) !important;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin-bottom: -4px;
                    background-size: 200% auto;
                    background-image: linear-gradient(to right, var(--primary-color) 0%, #f7be50 50%, var(--primary-color) 100%);
                    background-position: left center;
                    z-index: 1;
                }
            }

            .nav-item.active:hover {
                .nav-item-border {
                    background-position: right center;
                }
            }
        }

        .sidemenu-container {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 2000;
            display: flex;
            justify-content: flex-end;

            .sidemenu {
                width: 84%;
                max-width: 400px;
                height: 100%;
                overflow-y: auto;
                background-color: #18181b;

                .sidemenu-user {
                    background-color: var(--bg-color);
                    position: relative;

                    .sidemenu-user-border {
                        position: absolute;
                        bottom: 0;
                        transition: all 1s cubic-bezier(.25, .8, .25, 1) !important;
                        height: 3px;
                        width: 100%;
                        margin-bottom: -4px;
                        background-size: 200% auto;
                        background-image: linear-gradient(to right, var(--primary-color) 0%, #f7be50 50%, var(--primary-color) 100%);
                        background-position: left center;
                    }
                }

                .profile-pic-block {
                    position: relative;
                    width: 64px;
                    height: 64px;
                    margin-bottom: 8px;

                    .profile-pic {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        width: 100%;
                        border-radius: 100%;
                        z-index: 2;
                    }

                    .profile-pic-border {
                        position: absolute;
                        border-radius: 100%;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        margin: -3px;
                        background-image: linear-gradient(to right top, var(--primary-color) 0%, var(--primary-light-color) 100%);
                        z-index: 1;
                    }
                }

                .profile-username {
                    color: #ff7652;
                    font-weight: bold;
                }

                .sidemenu-nav {
                    .nav-item.active {
                        span {
                            color: var(--primary-color);
                        }
                    }
                    a {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        color: var(--text-color);
                        line-height: 2.5;

                        svg {
                            margin-left: 10px;
                            min-width: 20px;
                        }
                        span {
                            margin-left: 15px;
                        }
                    }
                }
            }

            .sidemenu:hover {
                .sidemenu-user {
                    .sidemenu-user-border {
                        background-position: right center;
                    }
                }
            }
        }

        main {
            margin-top: 54px;
            background-color: var(--bg-color);
        }

        .footer {
            width: 100%;
            background-color: #1f1f23;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            .logo {
                filter: grayscale(100%);
            }
        }
    }
</style>
