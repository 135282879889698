<template>
    <div class="row justify-content-center py-3 mx-1">
        <div class="col-xl-5">
            <div class="d-flex flex-column align-items-center justify-content-center mb-4 mt-4">
                <div class="profile-pic-block">
                    <div class="profile-pic-border shadow"></div>
                    <img :src="`${resourcesUrl}/${user.picture}`" class="profile-pic">
                </div>

                <span>You are connected as</span>
                <span class="profile-username">{{ user.username }}</span>
            </div>

            <div class="card shadow p-md-4">
                <div class="card-body">
                    <div v-if="!live.broadcastId">
                        <div class="form-group">
                            <label class="text-center w-100">
                                Live title
                                <emoji-text-input v-model="form.title" :loading="loading"/>
                            </label>
                        </div>

                        <div class="form-group d-flex flex-column justify-content-center align-items-center">
                            Orientation
                            <div class="orientation d-flex justify-content-center align-items-center" :class="form.orientation === 'h' ? 'horizontal' : 'vertical'" @click="switchOrientation">
                                <span>
                                    <template v-if="form.orientation === 'h'">Landscape</template>
                                    <template v-if="form.orientation === 'v'">Portrait</template>
                                </span>
                            </div>
                        </div>

                        <hr>

                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <button class="btn btn-lg btn-primary d-flex flex-row align-items-center justify-content-center"
                                    @click="createLive" :disabled="loading">
                                Create live
                                <loader color="#fff" class="ml-2" size="20px" v-if="loading"/>
                            </button>
                            <small class="form-text text-muted text-center">And get my stream key and RTMP URL</small>
                        </div>
                    </div>

                    <div v-if="live.broadcastId">
                        <h5 class="text-center">{{ live.title }}</h5>

                        <div class="mt-4 mb-3">
                            You can now copy and paste the RTMP URL and Stream Key into your favorite broadcasting
                            software and begin to stream on it.
                        </div>

                        <div class="form-group row" v-if="live.orientation === 'v'">
                            <div class="col-xl-12">
                                <hr>
                                <div class="d-flex flex-row align-items-center">
                                    <toggle-button ref="forcePortraitCheckbox" class="mr-3" color="var(--primary-color)" v-model="forcePortrait" @change="prepareStream" :disabled="loadingStream"/>
                                    <label @click="$refs.forcePortraitCheckbox.toggle()">
                                        Use InstaLive to rotate my landscape stream to a fullscreen portrait live on Instagram
                                    </label>
                                </div>
                                <hr>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center align-items-center h-100" v-if="loadingStream">
                            <loader size="100px" color="#ff7652"></loader>
                        </div>
                        <div v-else>
                            <div class="form-group row">
                                <label class="col-xl-3 col-form-label">RTMP upload URL</label>
                                <div class="col-xl-9">
                                    <div class="input-group">
                                        <input type="text" class="form-control" disabled :value="uploadUrl">
                                        <div class="input-group-append">
                                            <copy-button :value="uploadUrl"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="form-group row">
                                <label class="col-xl-3 col-form-label">RTMP URL</label>
                                <div class="col-xl-9">
                                    <div class="input-group">
                                        <input type="text" class="form-control" disabled :value="streamUrl">
                                        <div class="input-group-append">
                                            <copy-button :value="streamUrl"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-xl-3 col-form-label">Stream key</label>
                                <div class="col-xl-9">
                                    <div class="input-group">
                                        <input type="text" class="form-control" disabled :value="live.streamKey">
                                        <div class="input-group-append">
                                            <copy-button :value="live.streamKey"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div v-if="!live.started" class="text-danger text-center mb-2">
                                You are not live yet. When you'll go live, your followers will receive a notification that
                                you're live!
                            </div>

                            <div v-if="live.started" class="text-success text-center mb-2">
                                You are in live. You can see your live and interact with your community by clicking on the "<router-link :to="{name: 'live'}"><font-awesome-icon icon="play-circle" class="nav-icon"/> Live</router-link>" button.<br>
                                Entertain your followers!
                            </div>

                            <div class="d-flex justify-content-center mb-4 mt-4 flex-column align-items-center">
                                <button v-if="!live.started"
                                        class="btn btn-lg btn-primary d-flex flex-row align-items-center justify-content-center"
                                        @click="startLive" :disabled="loading">
                                    START LIVE
                                    <loader color="#fff" class="ml-2" size="20px" v-if="loading"/>
                                </button>

                                <button v-if="!live.started"
                                        class="btn btn-outline-primary d-flex flex-row align-items-center justify-content-center mt-3"
                                        @click="stopLive" :disabled="loading">
                                    Cancel
                                    <loader color="#fff" class="ml-2" size="20px" v-if="loading"/>
                                </button>

                                <button v-if="live.started"
                                        class="btn btn-lg btn-outline-primary d-flex flex-row align-items-center justify-content-center"
                                        @click="stopLive" :disabled="loading">
                                    STOP LIVE
                                    <loader color="#fff" class="ml-2" size="20px" v-if="loading"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from 'vue-spinner/src/MoonLoader'
    import EmojiTextInput from "../EmojiTextInput"
    import CopyButton from "../CopyButton"
    import { ToggleButton } from 'vue-js-toggle-button'

    export default {
        props: ['socket', 'user', 'live', 'comments', 'viewers', 'likes'],
        components: {
            CopyButton,
            EmojiTextInput,
            Loader,
            ToggleButton
        },
        data: function () {
            return {
                form: {
                    title: 'Live by InstaLive',
                    orientation: 'v'
                },
                forcePortrait: false,
                loading: false,
                loadingStream: false,
                instaliveStreamUrl: null
            }
        },
        computed: {
            uploadUrl () {
                if (this.loadingStream) {
                    return null
                }

                if (this.live.orientation === 'v' && this.forcePortrait) {
                    return this.instaliveStreamUrl + this.live.streamKey
                }

                return this.live.uploadUrl
            },
            streamUrl () {
                if (this.loadingStream) {
                    return null
                }

                if (this.live.orientation === 'v' && this.forcePortrait) {
                    return this.instaliveStreamUrl
                }

                return this.live.streamUrl
            },
        },
        methods: {
            createLive() {
                this.loading = true
                this.socket.emit('createLive', this.form, (live) => {
                    this.$emit('liveUpdated', live)
                    this.$emit('saveLastBroadcast', live)
                    this.loading = false
                })
            },
            startLive() {
                this.loading = true
                this.socket.emit('startLive', {broadcastId: this.live.broadcastId}, ({dashPlaybackUrl, dashAbrPlaybackUrl}) => {
                    let live = { ...this.live }
                    live.started = true
                    live.dashPlaybackUrl = dashPlaybackUrl
                    live.dashAbrPlaybackUrl = dashAbrPlaybackUrl
                    this.$emit('liveUpdated', live)
                    this.$emit('saveLastBroadcast', live)
                    this.loading = false
                })
            },
            stopLive() {
                this.loading = true
                this.socket.emit('stopLive', {broadcastId: this.live.broadcastId}, () => {
                    this.$emit('saveLastBroadcast', null)
                    this.$emit('liveUpdated', {
                        title: 'Live by InstaLive',
                        broadcastId: null,
                        uploadUrl: null,
                        streamKey: null,
                        streamUrl: null,
                        dashPlaybackUrl: null,
                        dashAbrPlaybackUrl: null,
                        started: false,
                    })
                    this.forcePortrait = false
                    this.instaliveStreamUrl = null
                    this.loading = false
                })
            },
            switchOrientation() {
                this.form.orientation = this.form.orientation === 'h' ? 'v' : 'h'
            },
            prepareStream() {
                if (this.forcePortrait) {
                    this.loadingStream = true
                    this.socket.emit('prepareStream', (res) => {
                        if (res) {
                            this.instaliveStreamUrl = res
                        } else {
                            this.instaliveStreamUrl = null
                            this.forcePortrait = false
                        }
                        this.loadingStream = false
                    })
                } else {
                    this.instaliveStreamUrl = null
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    h5 {
        margin: 0
    }

    .card {
        border-radius: 15px;
    }

    .profile-pic-block {
        position: relative;
        width: 64px;
        height: 64px;
        margin-bottom: 8px;

        .profile-pic {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
            border-radius: 100%;
            z-index: 2;
        }

        .profile-pic-border {
            position: absolute;
            border-radius: 100%;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: -3px;
            background-image: linear-gradient(to right top, var(--primary-color) 0%, var(--primary-light-color) 100%);
            z-index: 1;
        }
    }

    .profile-username {
        color: #ff7652;
        font-weight: bold;
    }

    .orientation {
        position: relative;
        border: 2px solid var(--primary-color);
        margin: 5px;
        border-radius: 10px;
        transition: all 1s cubic-bezier(.25, .8, .25, 1) !important;
        background-size: 200% auto;
        background-image: linear-gradient(to left bottom, var(--bg-color) 0%, rgba(255, 255, 255, 0.1) 30%, var(--bg-color) 100%);
        cursor: pointer;
        width: 72px;
        height: 128px;

        input {
            display: none;
        }

        span {
            text-shadow: 0 0 3px var(--bg-color);
            transition: all 1.5s cubic-bezier(.25, .8, .25, 1) !important;
        }

        &.vertical {
            background-position: left bottom;
        }

        &.horizontal {
            transform: rotateZ(-90deg);
            background-position: right bottom;

            span {
                transform: rotateZ(90deg);
            }
        }
    }
</style>