<template>
    <div class="h-100 w-100 d-flex justify-content-center flex-column">
        <div class="swiper-container" ref="mainContainer">
            <div class="swiper-wrapper">
                <div class="swiper-slide mt-2">
                    <comments
                            :socket="socket"
                            :user="user"
                            :live="live"
                            :comments="comments"
                            @comment="onCommentSent"/>
                </div>
                <div class="swiper-slide">
                    <preview :live="live" :viewers="viewers" :likes="likes"/>
                </div>
                <div class="swiper-slide mt-2">
                    <viewers-likes :viewers="viewers" :likes="likes"/>
                </div>
            </div>
        </div>
        <div class="pagination d-flex justify-content-center mb-3 mt-3">
            <div :class="index === 0 ? 'active' : ''" @click="setSlide(0)">
                <font-awesome-icon icon="comments" class="nav-icon" size="lg"/>
            </div>
            <div :class="index === 1 ? 'active' : ''" class="ml-5 mr-5" @click="setSlide(1)">
                <font-awesome-icon icon="play-circle" class="nav-icon" size="lg"/>
            </div>
            <div :class="index === 2 ? 'active' : ''" @click="setSlide(2)">
                <font-awesome-icon icon="users" class="nav-icon" size="lg"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Preview from "../../PreviewRxPlayer"
    import Comments from "../../Comments"
    import ViewersLikes from "../../ViewersLikes"
    import Swiper from 'swiper'

    export default {
        props: ['socket', 'user', 'live', 'comments', 'viewers', 'likes'],
        components: {
            ViewersLikes,
            Comments,
            Preview,
        },
        data: function () {
            return {
                swiper: null,
                index: 1
            }
        },
        mounted() {
            this.swiper = new Swiper(this.$refs.mainContainer, {
                direction: 'horizontal',
                simulateTouch: true,
                initialSlide: 1,

                on: {
                    slideChange: (swiper) => {
                        this.index = swiper.realIndex
                    },
                }
            })
        },
        beforeDestroy() {
            this.swiper.destroy()
            this.swiper = null
        },
        methods: {
            onCommentSent(comment) {
                this.$emit('commentSent', comment)
            },
            setSlide(slide) {
                this.swiper.slideTo(slide)
            }
        }
    }
</script>

<style scoped lang="scss">
    .card {
        margin-left: 15px;
        margin-right: 15px;
    }

    .swiper-container {
        height: 100%;
        width: 100%;

        .swiper-slide {
            height: auto;
        }
    }

    .pagination {
        div {
            height: 30px;
            width: 30px;
            transition: all .3s;
        }

        div.active {
            color: var(--primary-color);
            transform: scale(1.2);
        }
    }
</style>