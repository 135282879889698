<template>
    <div class="card shadow h-100">
        <div class="card-body">
            <h5 class="card-title">
                Viewers
            </h5>
            <transition name="fade">
                <div class="card-text viewers" v-if="viewers.length">
                    <a v-for="viewer in viewers" :key="viewer.id" class="viewer" :href="'https://www.instagram.com/' + viewer.username" target="_blank" v-tooltip="viewer.username">
                        <img :src="`${resourcesUrl}/${viewer.picture}`">
                    </a>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['viewers', 'likes'],
    }
</script>

<style scoped lang="scss">
    .card {
        border-radius: 15px;
    }

    .viewers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        .viewer {
            img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                margin: 5px;
            }
        }
    }

    .likers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .liker {
            position: relative;
            width: 32px;
            height: 32px;
            display: flex;
            margin: 5px;

            .liker-user-pic {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                margin-right: 10px;
            }

            .liker-badge {
                display: flex;
                position: absolute;
                border-radius: 100%;
                bottom: -5px;
                right: -5px;
                box-shadow: 0 0 2px 2px rgba(0, 0, 0, .2);
            }
        }
    }

    .card-title {
        margin-bottom: 0;
    }

    .card-text {
        margin-top: .75rem;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>