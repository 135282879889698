export default {
    install (Vue) {
        Vue.mixin({
            data () {
                return {
                    resourcesUrl: process.env.VUE_APP_RES_URL
                }
            }
        })
    }
}